import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { FullPageLoader } from "../../components/FullPageLoader";
import { routes } from "../../routes";

export default function ProtectedRoute() {
  const { isLoadingApp, isSignedIn } = useAuth();

  if (isLoadingApp) {
    return <FullPageLoader />;
  }

  return isSignedIn ? <Outlet /> : <Navigate to={routes.login} />;
}
