import { AuthService } from "../auth/types";
import { supabase } from "./supabaseClient";

function handleAuthResponse(user: any, error: any) {
  return {
    user,
    error,
  };
}

export const authServiceSupabase: AuthService = {
  getCurrentUser: async () => {
    const { data, error } = await supabase.auth.getUser();
    return handleAuthResponse(data?.user || null, error || null);
  },

  signIn: async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    return handleAuthResponse(data?.user || null, error || null);
  },
  signUp: async (email, password) => {
    const { data, error } = await supabase.auth.signUp({ email, password });
    return handleAuthResponse(data?.user || null, error || null);
  },
  signOut: async () => {
    const { error } = await supabase.auth.signOut();
    return handleAuthResponse(null, error || null);
  },
};
