import React from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";

import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { SETTING } from "./enums";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useAuth } from "../../services/auth/AuthProvider";

const settings: SETTING[] = [SETTING.PROFILE, SETTING.LOGOUT];

const NavBar = () => {
  const app = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = (menuItem: SETTING) => {
    switch (menuItem) {
      case SETTING.PROFILE:
        handleCloseUserMenu();
        navigate(routes.profile);
        break;
      case SETTING.LOGOUT:
        handleCloseUserMenu();
        app.signOut();
        break;
      default:
        handleCloseUserMenu();
        break;
    }
  };

  return (
    <AppBar
      position="sticky"
      elevation={1}
      sx={{ backgroundColor: theme.palette.common.white }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box component="a" href="/">
            <img
              src="/img/logo.png"
              alt="Xailand Real Estate Logo"
              style={{ width: "70px" }}
            />
          </Box>

          <Box flex={1} />
          <Box flex={0}>
            <Button
              endIcon={Boolean(anchorElUser) ? <ExpandLess /> : <ExpandMore />}
              onClick={handleOpenUserMenu}
              sx={{
                borderRadius: 0,
                border: `1px solid ${theme.palette.grey[200]}`,
                color: theme.palette.text.secondary,
                p: 1,
                "&:hover": {
                  border: `1px solid ${theme.palette.primary.main}`,
                  color: theme.palette.primary.main,
                },
              }}
              variant="outlined"
            >
              <Box
                sx={{
                  alignItems: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: 11, fontWeight: "bold" }}
                >
                  {app.user.email}
                </Typography>
              </Box>
            </Button>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map(setting => (
                <MenuItem
                  key={setting}
                  onClick={() => handleMenuItemClick(setting)}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
