import { useState, useEffect } from "react";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { useAuth } from "../services/auth/AuthProvider";

const REALM_APP_ID = process.env.REACT_APP_APP_ID;

// Create an ApolloClient that connects to the provided Realm.App's GraphQL API
const createRealmApolloClient = app => {
  const link = new HttpLink({
    // Realm apps use a standard GraphQL endpoint, identified by their App ID
    uri: `${process.env.REACT_APP_MONGO_URI}/${REALM_APP_ID}/graphql`,
    // A custom fetch handler adds the logged in user's access token to GraphQL requests
    fetch: async (uri, options) => {
      if (!app.isSignedIn) {
        throw new Error(`Must be logged in to use the GraphQL API`);
      }
      // Refreshing a user's custom data also refreshes their access token
      await app.refreshToken();
      // TODO: Include the current user's access token in an Authorization header with
      // every request.
      const headersInit: HeadersInit = {};
      options.headers = headersInit;
      const accessToken = await app.getAccessToken();
      options.headers.Authorization = `Bearer ${accessToken}`;
      return fetch(uri, options);
    },
  });

  const cache = new InMemoryCache();

  return new ApolloClient({
    link,
    cache,
  });
};

export default function RealmApolloProvider({ children }) {
  // TODO: Create an ``ApolloClient`` object that connects to your app.
  const app = useAuth();
  const [client, setClient] = useState(createRealmApolloClient(app));
  useEffect(() => {
    setClient(createRealmApolloClient(app));
  }, [app]);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
