import React, { useState, useEffect } from "react";
import validator from "validator";
import {
  Alert,
  Box,
  Link,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import xaiLandImage from "../../assets/image/logo/logo.png";

import { AUTH_ERROR_CODES, AUTH_MODE } from "./enums";
import { useAuth } from "../../services/auth/AuthProvider";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";

const Copyright = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://xai.land/">
        XAI Land
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const parseAuthenticationError = (err: ApiLoginError) => {
  const parts = err.message.split(":");
  const reason = parts[parts.length - 1].trimStart();
  if (!reason) return { status: "", message: "" };
  const reasonRegex = /(?<message>.+)\s\(status (?<status>[0-9][0-9][0-9])/;
  const match = reason.match(reasonRegex);
  const { status, message } = match?.groups ?? {};
  return { status, message };
};

const LoginPage = () => {
  const app = useAuth();
  const navigate = useNavigate();
  const [mode] = useState<(typeof AUTH_MODE)[keyof typeof AUTH_MODE]>(
    AUTH_MODE.LOGIN
  );
  const theme = useTheme();
  // Keep track of form input state
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  // Keep track of input validation/errors
  const [error, setError] = useState<LoginError>({});

  // Whenever the mode changes, clear the form inputs
  useEffect(() => {
    setError({});
  }, [mode]);

  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (mode === AUTH_MODE.LOGIN) {
      handleLogin();
      return;
    }
    if (mode === AUTH_MODE.REGISTER) {
      handleRegistrationAndLogin();
      return;
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    setError(prevError => ({ ...prevError, password: null }));
    try {
      await app.signIn(email, password);
      setLoading(false);
      navigate(routes.root);
    } catch (err) {
      handleAuthenticationError(err);
      setLoading(false);
    }
  };

  const handleRegistrationAndLogin = async () => {
    const isValidEmailAddress = validator.isEmail(email);
    if (!isValidEmailAddress) {
      setError(err => ({ ...err, email: "이메일이 일치하지 않습니다." }));
      return;
    }

    setLoading(true);
    try {
      await app.signUp(email, password);
      setLoading(false);
      return await handleLogin();
    } catch (err) {
      setLoading(false);
      handleAuthenticationError(err);
    }
  };

  const handleAuthenticationError = (err: ApiLoginError) => {
    const { status, message } = parseAuthenticationError(err);
    const errorType = message || status;
    switch (errorType) {
      case AUTH_ERROR_CODES.INVALID_USERNAME:
      case AUTH_ERROR_CODES.INVALID_USERNAME_PASSWORD:
      case AUTH_ERROR_CODES.INVALID_PASSWORD:
      case AUTH_ERROR_CODES.UNAUTHORIZED:
        setError(err => ({
          ...err,
          email: "이메일이 일치하지 않습니다.",
          password: "암호가 일치하지 않습니다.",
        }));
        break;
      case AUTH_ERROR_CODES.NAME_IN_USE:
      case AUTH_ERROR_CODES.CONFLICT:
        setError(err => ({ ...err, email: "이메일이 이미 등록되었습니다." }));
        break;
      case AUTH_ERROR_CODES.PASSWORD_POLICY:
      case AUTH_ERROR_CODES.BAD_REQUEST:
        setError(err => ({
          ...err,
          password: "암호는 6글자에서 128즐자 사이여야 합니다.",
        }));
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "rgb(128, 217, 138)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Paper
        component="form"
        noValidate
        onSubmit={handleFormSubmit}
        style={{
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            alignItems: "flex-end",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          width={1}
        >
          <Box>
            <img src={xaiLandImage} alt="Xailand Logo" style={{ width: 100 }} />
          </Box>
        </Box>
        <Box
          sx={{
            border: `1px solid ${theme.palette.grey[200]}`,
            p: 2,
            my: 2,
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={!!error.email}
            onChange={e => {
              setEmail(e.target.value);
            }}
            sx={{
              "&.MuiInputBase-root": {
                borderRadius: 0,
              },
              "&.MuiOutlinedInput-root:hover": {
                fieldset: {
                  borderColor: theme.palette.grey[200],
                },
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={!!error.password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            sx={{
              "&.MuiInputBase-root": {
                borderRadius: 0,
              },
              "&.MuiOutlinedInput-root:hover": {
                fieldset: {
                  borderColor: theme.palette.grey[200],
                },
              },
            }}
          />

          <Box my={2}>
            {error.email || error.password ? (
              <Alert severity="error">
                로그인 오류: 이메일과 암호가 일치하지 않습니다. 다시
                시도하십시오.
              </Alert>
            ) : null}
          </Box>
          <LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{
              "&.MuiButtonBase-root": {
                borderRadius: 0,
              },
            }}
          >
            <Typography variant="subtitle2">
              {mode === AUTH_MODE.LOGIN ? `로그인` : `회원가입`}
            </Typography>
          </LoadingButton>
        </Box>
        <Copyright />
      </Paper>
    </Box>
  );
};

export default LoginPage;
