import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

import "../styles/Layout.css";
import { routes } from "../routes";

import NavBar from "./NavBar";
import Footer from "./Footer";
import { AddressSearchProvider } from "./AddressSearch/context";

const Layout: React.FC = () => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar />

      <main style={{ flex: 1 }}>
        <div
          style={{
            display: "flex",
          }}
        >
          <AddressSearchProvider resultUrl={routes.root}>
            <Outlet />
          </AddressSearchProvider>
        </div>
      </main>

      <footer>
        <Footer />
      </footer>
    </Box>
  );
};

export default Layout;
