import React from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import theme from "./theme";

interface CustomThemeProviderProps {
  children: React.ReactNode;
}

export const CustomThemeProvider: React.FC<CustomThemeProviderProps> = ({
  children,
}) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default CustomThemeProvider;
