import { createTheme, ThemeOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    customColors: {
      orange: string;
      purple: string;
    };
  }

  interface ThemeOptions {
    customColors?: {
      orange?: string;
      purple?: string;
    };
  }
}

// Define your theme options
const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: `rgb(128, 217, 138)`,
      light: `rgba(128, 217, 138, 50%)`,
      dark: "#489651",
    },
    secondary: {
      main: "#252B48",
      light: "#3A3F5C",
      dark: "#1E2139",
    },

    info: {
      main: "#72BAFC",
      light: "#A3D2FC",
      dark: "#4A8CCF",
    },
  },
  customColors: {
    orange: "#FF9800",
    purple: "#9C27B0",
  },
};

// Create and export the theme
const theme = createTheme(themeOptions);

export default theme;
