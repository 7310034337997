import { createContext, useContext, useEffect, useState } from "react";
import * as Realm from "realm-web";
import { AuthProviderProps } from "./types";
import { authServiceSupabase } from "../supabase/authService";
import { supabase } from "../supabase/supabaseClient";
import { useClearStorageOnDeploy } from "../../hooks/useClearStorageOnDeploy";

/**
 * MongoDB service account credentials
 * TODO: Delete mongodb code after migration to supabase
 */
const MONGODB_SERVICE_EMAIL = process.env.REACT_APP_MONGO_SERVICE_EMAIL;
const MONGODB_SERVICE_PASSWORD = process.env.REACT_APP_MONGO_SERVICE_PASSWORD;
const REALM_APP_ID = process.env.REACT_APP_APP_ID;

// Create a MongoDB Realm app instance
const realmApp = new Realm.App(REALM_APP_ID || "");

const AuthContext = createContext<AuthProviderProps | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoadingApp, setIsLoadingApp] = useState(true);
  const [mongoDBUser, setMongoDBUser] = useState<Realm.User | null>(null);
  const supabaseAuthService = authServiceSupabase;

  /**
   * TODO: Delete hook if not needed after migration to supabase
   */
  useClearStorageOnDeploy("1.0.0");

  // Connect to MongoDB with service account
  const connectToMongoDB = async () => {
    try {
      // Check if we already have a valid MongoDB user
      if (realmApp.currentUser && realmApp.currentUser.isLoggedIn) {
        setMongoDBUser(realmApp.currentUser);
        return realmApp.currentUser;
      }

      // Otherwise log in with the service account
      const credentials = Realm.Credentials.emailPassword(
        MONGODB_SERVICE_EMAIL || "",
        MONGODB_SERVICE_PASSWORD || ""
      );

      const user = await realmApp.logIn(credentials);
      setMongoDBUser(user);
      return user;
    } catch (error) {
      console.error("Failed to connect to MongoDB:", error);
      return null;
    }
  };

  useEffect(() => {
    const initializeUser = async () => {
      const { user } = await supabaseAuthService.getCurrentUser();
      setCurrentUser(user);

      /**
       * TODO: Delete mongodb code after migration to supabase
       */
      if (user) {
        await connectToMongoDB();
      }

      setIsLoadingApp(false);
    };

    initializeUser();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setCurrentUser(session?.user ?? null);
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [supabaseAuthService]);

  const handleSignIn = async (email: string, password: string) => {
    setIsLoadingApp(true);
    try {
      const result = await supabaseAuthService.signIn(email, password);
      setCurrentUser(result.user);

      /**
       * TODO: Delete mongodb code after migration to supabase
       */
      await connectToMongoDB();

      return result;
    } catch (error) {
      console.error("Sign in failed:", error);
      throw error;
    } finally {
      setIsLoadingApp(false);
    }
  };

  const handleSignUp = async (email: string, password: string) => {
    setIsLoadingApp(true);
    try {
      const result = await supabaseAuthService.signUp(email, password);
      setCurrentUser(result.user);

      /**
       * TODO: Delete mongodb code after migration to supabase
       */
      await connectToMongoDB();

      return result;
    } catch (error) {
      console.error("Sign up failed:", error);
      throw error;
    } finally {
      setIsLoadingApp(false);
    }
  };

  const handleSignOut = async () => {
    setIsLoadingApp(true);
    try {
      const result = await supabaseAuthService.signOut();
      setCurrentUser(null);
      return result;
    } catch (error) {
      console.error("Sign out failed:", error);
      throw error;
    } finally {
      setIsLoadingApp(false);
    }
  };


  const value = {
    /**
     * TODO: Delete mongodb code after migration to supabase
     */
    getAccessToken: async () => {
      const user = mongoDBUser || (await connectToMongoDB());
      return user?.accessToken || "";
    },

    refreshToken: async () => {
      if (mongoDBUser) {
        try {
          await mongoDBUser.refreshAccessToken();
          return mongoDBUser.accessToken;
        } catch (err) {
          // If token refresh fails, reconnect
          const user = await connectToMongoDB();
          return user?.accessToken || null;
        }
      }
      return null;
    },

    isSignedIn: !!currentUser,
    isLoadingApp,
    signIn: handleSignIn,
    signUp: handleSignUp,
    signOut: handleSignOut,
    user: {
      id: currentUser?.id || "",
      email: currentUser?.email || "",
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
