import { useEffect } from "react";

/**
 * Custom hook to clear localStorage and sessionStorage on new deployments
 * while preserving specified keys
 * @param version The current version identifier
 * @param keysToKeep Array of localStorage keys to preserve
 * TODO: Delete this hook if not needed after migration to supabase
 */
export const useClearStorageOnDeploy = (
  version: string,
  keysToKeep: string[] = ["i18nextLng"]
) => {
  useEffect(() => {
    const versionKey = `APP_CLEARED_FOR_${version}`;

    if (!localStorage.getItem(versionKey)) {
      const preservedData: Record<string, string> = {};
      keysToKeep.forEach(key => {
        const value = localStorage.getItem(key);
        if (value !== null) {
          preservedData[key] = value;
        }
      });

      localStorage.clear();
      sessionStorage.clear();

      Object.entries(preservedData).forEach(([key, value]) => {
        localStorage.setItem(key, value);
      });

      localStorage.setItem(versionKey, "true");

      window.location.reload();
    }
  }, [version, keysToKeep]);
};
