import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ThemeProvider from "./theme/ThemeProvider";

import RealmApolloProvider from "./graphql/RealmApolloProvider";
import Layout from "./components/Layout";
import LoginPage from "./pages/LoginPage";
import UnitDetailsPage from "./pages/UnitDetailsPage";
import { routes } from "./routes";
import SelectionPage from "./pages/SelectionPage";
import { AuthProvider } from "./services/auth/AuthProvider";
import ProtectedRoute from "./services/auth/ProtectedRoute";
import { FullPageLoader } from "./components/FullPageLoader";

// 코드 분할
const ProfilePage = React.lazy(() => import("./pages/ProfilePage"));
const DashboardPage = React.lazy(() => import("./pages/DashboardPage"));

export default function App() {
  return (
    <AuthProvider>
      <RealmApolloProvider>
        <ThemeProvider>
          <Suspense fallback={<FullPageLoader />}>
            <BrowserRouter>
              <Routes>
                {/* Public Routes */}
                <Route path={routes.login} element={<LoginPage />} />

                {/* Protected Routes */}
                <Route element={<ProtectedRoute />}>
                  <Route path={routes.root} element={<Layout />}>
                    <Route index element={<SelectionPage />} />
                    <Route path={routes.report} element={<UnitDetailsPage />} />
                    <Route path={routes.profile} element={<ProfilePage />} />
                    <Route
                      path={routes.dashboard}
                      element={<DashboardPage />}
                    />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </Suspense>
        </ThemeProvider>
      </RealmApolloProvider>
    </AuthProvider>
  );
}
